import { LINE_DUPLICATE_COUNT, LINE_SEGMENTATION_COUNT, FACTOR_MULTIPLIER } from './constants';
import { midpoint } from './utils';

const Line = (x1, y1, x2, y2) => {
  const p1 = { x: x1, y: y1 };
  const p2 = { x: x2, y: y2 };
  const sublines = [];

  sublines.type = 'LINE';

  for (let factor = 1; factor <= LINE_DUPLICATE_COUNT; factor += 1) {
    const points = [];

    for (let i = 0; i <= LINE_SEGMENTATION_COUNT; i += 1) {
      const percent = i / LINE_SEGMENTATION_COUNT;
      const point = midpoint(p1, p2, percent);

      point.factor = factor * FACTOR_MULTIPLIER;
      points.push(point);
    }

    points.type = 'LINE';
    sublines.push(points);
  }

  return sublines;
};

export default Line;
