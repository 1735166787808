import Effect from './src/Effect';

import MoveTo from './src/MoveTo';
import Line from './src/Line';
import Curve from './src/BezierCurve';

(() => {
  const createAB = () => {
    const segments = [
      Line(0, 5.0, 5.074, 18.475),
      Line(5.074, 18.475, 10.686, 18.475),
      Line(10.686, 18.475, 25.289, 18.475),
      Line(25.289, 18.475, 31.256, 18.477),
      Line(31.256, 18.477, 36.391, 5.0),
      Line(36.391, 5.0, 30.217, 5.0),
      Line(30.217, 5.0, 27.07, 13.549),
      Line(27.07, 13.549, 8.904, 13.549),
      Line(8.904, 13.549, 5.877, 5.0),
      Line(5.877, 5.0, 0, 5.0),

      Line(5.074, 18.475, 14.842, 44.418),
      Line(14.842, 44.418, 21.371, 44.418),
      Line(21.371, 44.418, 31.256, 18.475),
      Line(31.256, 18.475, 25.289, 18.475),
      Line(25.289, 18.475, 17.928, 38.6),
      Line(17.928, 38.6, 10.686, 18.475),
      Line(10.686, 18.475, 5.074, 18.475),

      MoveTo(169.887, 55.652),

      Line(169.887, 55.652, 169.887, 95.068),
      Line(169.887, 95.068, 175.525, 95.068),
      Line(175.525, 95.068, 175.525, 90.082),
      Line(175.525, 90.082, 175.525, 78.27),
      Line(175.525, 78.27, 175.525, 73.58),
      Line(175.525, 73.58, 175.525, 60.639),
      Line(175.525, 60.639, 175.525, 55.652),
      Line(175.525, 55.652, 169.887, 55.652),

      MoveTo(175.525, 95.068),

      Line(175.525, 95.068, 184.313, 95.068),
      Curve(184.313, 95.068, 193.75, 95.068, 198.381, 85.273, 198.381, 91.81),
      Curve(198.381, 85.273, 198.381, 83.31, 197.193, 80.109, 198, 81.6),
      Curve(197.193, 80.109, 196.42, 78.51, 193.928, 76.607, 195.34, 77.34),
      Curve(193.928, 76.607, 198, 74.91, 199.984, 67.287, 199.984, 71.73),
      Curve(199.984, 67.287, 199.984, 63.29, 196.066, 58.441, 198.7, 60.33),
      Curve(196.066, 58.441, 193.48, 56.55, 185.678, 55.652, 190.04, 55.652),
      Line(185.678, 55.652, 175.525, 55.652),
      Line(175.525, 55.652, 175.525, 60.639),
      Line(175.525, 60.639, 185.736, 60.639),
      Curve(185.736, 60.639, 191.41, 60.639, 194.225, 67.229, 194.229, 63.84),
      Curve(194.225, 67.229, 194.225, 69.5, 192.088, 72.096, 193.53, 71.11),
      Curve(192.088, 72.096, 190.7, 73.03, 185.381, 73.58, 188.49, 73.58),
      Line(185.381, 73.58, 175.525, 73.58),
      Line(175.525, 73.58, 175.525, 78.27),
      Line(175.525, 78.27, 185.143, 78.27),
      Curve(185.143, 78.27, 187.63, 78.27, 190.723, 79.693, 189.46, 78.75),
      Curve(190.723, 79.693, 192.05, 80.61, 192.682, 84.502, 192.71, 82.22),
      Curve(192.682, 84.502, 192.682, 88.19, 185.143, 90.082, 190.19, 90.082),
      Line(185.143, 90.082, 175.525, 90.082),
      Line(175.525, 90.082, 175.525, 95.068)
    ];

    const effect = Effect({
      segments,
      width: 200,
      height: 100,
      color: 'rgba(0, 0, 0, 0.3)',
      scale: { x: 3, y: -3 }
    });

    effect.start();
  };

  const init = () => {
    if (window.innerWidth > 750) {
      createAB();
    }
  };

  document.addEventListener('DOMContentLoaded', init);
})();
