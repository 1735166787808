import { DISTORT_RADIUS, SMOOTH_EXPONANT } from './constants';

export const dist = (p1, p2) => {
  const { x: x1, y: y1 } = p1;
  const { x: x2, y: y2 } = p2;

  const dx = x1 - x2;
  const dy = y1 - y2;

  return Math.abs(Math.hypot(dx, dy));
};

export const computeCpt = (p1, p2, percent) => ({
  cpx: p1.cpx ? p1.cpx + (p2.x - p1.cpx) * percent : 0,
  cpy: p1.cpx ? p1.cpy + (p2.y - p1.cpy) * percent : 0,
});

export const midpoint = (p1, p2, percent) => ({
  x: p1.x + (p2.x - p1.x) * percent,
  y: p1.y + (p2.y - p1.y) * percent,
  ...computeCpt(p1, p2, percent),
});

export const computeEffectivePoint = (point, cursor) => {
  const { factor } = point;
  const d = DISTORT_RADIUS - Math.min(dist(point, cursor), DISTORT_RADIUS);
  const dpercent = d / DISTORT_RADIUS;
  const dd = (dpercent ** SMOOTH_EXPONANT) / factor;

  return midpoint(point, cursor, dd);
};
