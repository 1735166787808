import { LINE_DUPLICATE_COUNT, FACTOR_MULTIPLIER } from './constants';

const BezierCurve = (x1, y1, cp1x, cp1y, x2, y2, cp2x, cp2y) => {
  const curves = [];

  curves.type = 'CURVE';

  for (let factor = 1; factor <= LINE_DUPLICATE_COUNT; factor += 1) {
    const currentFactor = factor * FACTOR_MULTIPLIER;

    const p1 = {
      x: x1,
      y: y1,
      cpx: cp1x,
      cpy: cp1y,
      factor: currentFactor,
    };

    const p2 = {
      x: x2,
      y: y2,
      cpx: cp2x,
      cpy: cp2y,
      factor: currentFactor,
    };

    const curve = [p1, p2];
    curve.type = 'CURVE';

    curves.push(curve);
  }

  return curves;
};

export default BezierCurve;
